import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO title="Kontakta Förstärkeriet" />
    <h2>Kontakt</h2>
    <p>
      <a href="mailto:forstarkeriet@gmail.com">forstarkeriet@gmail.com</a>
    </p>
    <p>
      <a href="tel:+46705972947">Tel: 0705 97 29 47</a>
    </p>
    <p>Hör gärna av dig med din idé!</p>
  </Layout>
)

export default Contact
